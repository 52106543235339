<template>
  <div>
    <div class="bg">
      <img class="bg-img" src="../assets/conclave/background.png" />
    </div>

    <div class="content">
      <h1 class="title">Claim Gift</h1>

      <!-- Input field for claiming -->
      <p class="label-username"> Enter your Conclave username </p>
      <input class="field-username" v-model="username" placeholder="username#0000" @input="onUsernameChanged" @keyup.enter="claim"  />

      <!-- Claim button -->
      <p v-if="resultMessage" class="label-username" v-html="resultMessage"></p>
      <div v-else>
        <p class="user-not-found"> {{ userNotFound ? "User not found" : "" }} </p>
        <button class="btn-claim" @click="claim" :disabled="!isUsernameValid() || isClaiming">Claim</button>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  name: "View-ConclaveClaimGift",
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      username: "",
      isClaiming: false,
      userNotFound: false,
      resultMessage: null,
    };
  },
  methods: {
    claim() {
      if(!this.isUsernameValid()){
        return
      }

      let claimableId = this.$route.query.id;
      if(!claimableId){
        claimableId = "no-gift";
      }

      const name = this.username.split("#")[0]
      const username_id = parseInt(this.username.split("#")[1])

      const body = JSON.stringify({data: {username: name, username_id: username_id, claimable_id: claimableId}});

      console.log(body)
      
      this.isClaiming = true;
      fetch("https://europe-west1-conclave-decf3.cloudfunctions.net/claimClaimable", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      })
      .then(response => {
        this.isClaiming = false;
        if (!response.ok) {
          return response.text().then(text => { throw new Error(text) })
        }
        return response.json();
      })
      .then(data => {
        const claimedItems = data.result;
        // console.log('Claimed tickets:', claimedItems.ticket_count);
        let message = `Congratulations!<br>`;
        if(claimedItems.aldor){
          message += `<br>Claimed ${claimedItems.aldor} aldor`
        }
        if(claimedItems.ticket_count){
          message += `<br>Claimed ${claimedItems.ticket_count} tickets`
        }
        if(claimedItems.cards_dissension){
          message += `<br>Claimed ${claimedItems.cards_dissension.length} cards`
        }
        this.resultMessage = message;
      })
      .catch(error => {
        // Handle errors
        const err = JSON.parse(error.message).error;
        // console.error('Error making HTTP request:', err.message);
        if(err.message == "user_not_found"){
          this.userNotFound = true;
        }else if(err.message == "user_already_claimed"){
          this.resultMessage = "You have already claimed this gift";
        }else if(err.message == "claimable_not_found"){
          this.resultMessage = "Gift not found";
        }else if(err.message == "claimable_not_active"){
          this.resultMessage = "Gift no longer claimable";
        }else{
          this.resultMessage = err.message;
        }
        
      });
    },
    isUsernameValid() {
      const pattern = /^[^#]{2,}#\d{4}$/; // At least 2 characters before # and exactly 4 digits after #
      return pattern.test(this.username);
    },
    onUsernameChanged() {
      this.userNotFound = false;
      this.resultMessage = null;
    }
  },
};
</script>
  
  <style scoped>
  .bg {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    background-color: black;
  }
  .bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(20%) blur(10px);
  }
  
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(var(--highlight-color));
    font-family: 'Conclave';
  }
  
  .title {
    font-weight: normal;
    transform: scaleY(1.4);
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
  }
  .desktop-view .title {
    font-size: 65px;
    margin-bottom: 15px;
  }
  .mobile-view .title {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  .label-username {
    margin-top: 50px;
  }
  .user-not-found {
    font-family: 'Conclave';
    font-size: 16px;
    height: 20px;
    margin-top: 10px;
  }
  .field-username {
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Conclave';
    font-size: 18px;
    height: 30px;
    width: 235px;
  }
  .btn-claim {
    font-family: 'Conclave';
    font-size: 18px;
    margin-top: 20px;
    height: 40px;
    width: 260px;
  }
</style>
