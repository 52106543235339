<template>
    <div class="lore">
        <h2>History of Malvolith</h2>
        <h3>Chapter 1: Genesis</h3>
        <p>
            In the beginning, there was nothing but darkness and chaos.
            From this primordial abyss, five powerful demons emerged, each possessing unique abilities and desires.
            These demons were Envali, Kangir, Miir, Arcis, and Thero.
        </p>
        <p>
            Envali, the demon of wisdom, was the first to speak.<br>
            “We are gods, imbued with unimaginable power”, she said, “but what use is power if there is no one to witness it?”
        </p>
        <p>
            The demon of strength, Kangir, was quick to agree.<br>
            “We must create a world where we can test our might. Let us see who is the strongest among us.”
        </p>
        <p>
            And so, the five demons set to work, each creating their own race of beings to populate their new world.<br>
            Envali created the elfs, elegant and wise.
            Kangir the orcs, fierce and powerful.
            Miir the enchanting and mischievous nymphs.
            Arcis the shadowy and cunning wraiths,
            and Thero the sturdy and disciplined dwarfs.

            As the world took shape, the demons watched from afar, eager to see how their creations would fare in this new and wondrous place.
            They had agreed that the demon whose race conquered the world would be declared the strongest among them, and so the competition began.
        </p>
        <p>
            As the different races spread across the land, each seeking dominance over the others, the elves stood tall and proud. With their knowledge and magical abilities far surpassing those of any other race, they believed it was their duty to lead the world to enlightenment. Their shimmering citadels glinted in the sun, a testament to their advanced civilization, as they sought to bring the other races under their guidance and rule.
        </p>
        <p>
            But the elves were not alone in their quest for power. The orcs, valuing their own sovereignty over all and with a deep desire for glory, rode forth on their fearsome war beasts, crushing all who stood in their way. Lost in their obsession with the beauty of nature, the Nymphs trampled over anything that didn't conform to their ideal, leaving behind a trail of destruction in their wake. The wraiths, with their mastery of deception and manipulation, lurked in the shadows, pulling the strings of those in power and leaving the world unaware of their true influence.
        </p>
        <p>
            Amidst this chaos, the dwarfs stood firm. Frustrated by the chaos that plagued the land, they sought to bring stability through their disciplined and organized ways. Their underground cities were a marvel of engineering and architecture, as they sought to impose their strict laws and order upon all. But even they could not foresee the tumultuous future that lay ahead. As the ages passed, the demons watched from the shadows, waiting to see which of their creations would emerge victorious. And while the battles raged on, the demons grew stronger, feeding on the suffering and strife that they had created.
        </p>
        <p>
            
        </p>
        <p>
            Who will emerge as the strongest demon, and which race will conquer the world? Only time will tell in this epic struggle of power and might.
        </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Component-ConclaveLore'
  }
  </script>

<style scoped>
.desktop-view .lore {
    text-align: center;
    max-width: 1100px;
}

.mobile-view .lore {
    text-align: justify;
    padding-left: 15px;
    padding-right: 15px;
  }
</style>