import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Conclave from './views/Conclave.vue'
import ConclaveClaimGift from './views/ConclaveClaimGift.vue'
import ConclaveUnsubscribeEmail from './views/ConclaveUnsubscribeEmail.vue'
import ConclaveCommunityGuidelines from './views/CommunityGuidelines.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import Support from './views/Support.vue'

const routes = [
  /*{
    path: '/',
    component: Home
  },*/
  {
    path: '/conclave',
    component: Conclave
  },
  {
    path: '/conclave/guidelines',
    component: ConclaveCommunityGuidelines
  },
  {
    path: '/conclave/gift',
    component: ConclaveClaimGift
  },
  {
    path: '/conclave/unsubscribe',
    component: ConclaveUnsubscribeEmail
  },
  {
  path: '/support',
    component: Support,
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/conclave'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    }
})

export default router