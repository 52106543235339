<template>
    <div class="pager"
      @mousedown="onTouchStart"
      @mousemove="onTouchMove"
      @mouseup="onTouchEnd"
      @mouseleave="onTouchEnd"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
      >
      <div class="pager-container" ref="container">
        <div class="pager-item" v-for="(item, index) in items" :key="index" ref="item">
          <component :is="item.component" :data="item.data" />
        </div>
      </div>
      <div class="pager-dots">
        <span v-for="(item, index) in items" :key="index" :class="{ active: currentIndex === index }" @click="goTo(index)"></span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        currentIndex: 0,
        containerWidth: 0,
        touchStartX: 0,
        touchEndX: 0,
        isDragging: false,
        autoPage: 5000,
        intervalId: null, // store the ID of the interval
      };
    },
    mounted() {
      this.containerWidth = this.$refs.container.clientWidth;
      this.intervalId = setInterval(this.next, this.autoPage); // auto-paging every 4 seconds
    },
    methods: {
      goTo(index) {
        this.currentIndex = index;
        this.resetInterval(); // reset the interval when a manual transition occurs
      },
      next() {
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
        this.resetInterval(); // reset the interval when a manual transition occurs
      },
      prev() {
        this.currentIndex = (this.currentIndex + this.items.length - 1) % this.items.length;
        this.resetInterval(); // reset the interval when a manual transition occurs
      },
      onTouchStart(event) {
        // event.preventDefault();
        this.isDragging = true;
        this.touchStartX = this.getTouchX(event);
      },
      onTouchMove(event) {
        // event.preventDefault();
        if (!this.isDragging) {
          return;
        }
        const touchX = this.getTouchX(event);
        const delta = touchX - this.touchStartX;
        if (Math.abs(delta) > 50) {
          this.$refs.container.style.transform = `translateX(${-this.currentIndex * this.containerWidth + delta}px)`;
        }
      },
      onTouchEnd(event) {
        // event.preventDefault();
        if (!this.isDragging) {
          return;
        }
        const touchX = this.getTouchX(event);
        const delta = touchX - this.touchStartX;
        if (Math.abs(delta) > 50) {
          if (delta > 0) {
            this.prev();
          } else {
            this.next();
          }
        }
        this.isDragging = false;
        this.$refs.container.style.transform = `translateX(${-this.currentIndex * this.containerWidth}px)`;
      },
      getTouchX(event) {
        return event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
      },
      resetInterval() {
        clearInterval(this.intervalId); // clear the existing interval
        this.intervalId = setInterval(this.next, this.autoPage); // create a new interval
      },
    },
    watch: {
      currentIndex(value) {
        const translateX = -value * this.containerWidth;
        this.$refs.container.style.transform = `translateX(${translateX}px)`;
      },
    },
  };
</script>

  
  <style scoped>
  .pager {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .pager-container {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.1s;
  }
  .pager-item {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }
  .pager-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .pager-dots span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: rgba(var(--highlight-color), 0.3);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .pager-dots span.active {
    background-color: rgb(var(--highlight-color));
  }
  </style>
  