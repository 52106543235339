<template>
  <div class="bg">
    <img class="bg-img" src="../assets/conclave/background.png" />
  </div>
  
  <div class="content">
    <h1 class="title">Play Conclave</h1>
    <pager class="promo" :items="slides" />
    <div class="badges">
      <AppStoreBadge/>
      <GooglePlayBadge/>
    </div>
    <ConclaveDescription class="description"/>
    <ConclaveLore class="lore"/>
  </div>

</template>

<script>
import ConclaveLore from '@/components/ConclaveLore.vue';
import ConclaveDescription from '@/components/ConclaveDescription.vue';
import AppStoreBadge from '@/components/AppStoreBadge.vue';
import GooglePlayBadge from '@/components/GooglePlayBadge.vue';
import Pager from "@/components/PagerComponent.vue";
import Page1 from "@/components/conclave_pages/PromoPage1.vue";
import Page2 from "@/components/conclave_pages/PromoPage2.vue";
import Page3 from "@/components/conclave_pages/PromoPage3.vue";
import Page4 from "@/components/conclave_pages/PromoPage4.vue";

export default {
  name: "View-Conclave",
  components: {
    ConclaveLore,
    ConclaveDescription,
    GooglePlayBadge,
    AppStoreBadge,
    Pager,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      slides: [
        {
          component: Page1,
          data: { },
        },
        {
          component: Page2,
          data: { },
        },
        {
          component: Page3,
          data: { },
        },
        {
          component: Page4,
          data: { },
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-color: black;
}
.bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(20%) blur(10px);
}


.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(var(--highlight-color));
  font-family: 'Conclave';
}

.promo {
  height: 55vh;
  width: 85%;
  margin-top: 10px;
  display: flex;
  justify-content: center; /* center child horizontally */
}

.lore{
  padding-top: 20vh;
}

.badges {
  display: flex;
  justify-content: center;
}
.desktop-view .badges >>> .store-badge {
  margin: 15px;
  height: 60px;
}
.mobile-view .badges >>> .store-badge {
  margin: 5px;
  height: 50px;
}

.title {
  font-weight: normal;
  transform: scaleY(1.4);
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
}
.desktop-view .title {
  font-size: 65px;
  margin-bottom: 15px;
}
.mobile-view .title {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
