<template>
  <div class="content">
    <h1>Conclave Community Guidelines</h1>

    
    <h2>1. Respectful Behavior</h2>
    <ul>
        <li>Treat others with respect, kindness, and fairness.</li>
        <li>Refrain from engaging in personal attacks, harassment, spamming, or engaging in disruptive behavior that interferes with other players' experience.</li>
    </ul>
    
    <h2>2. Appropriate Language</h2>
    <ul>
        <li>Use appropriate and respectful language in all interactions and user-generated content.</li>
        <li>Do not use profanity, sexually explicit content, or offensive slurs.</li>
    </ul>
    
    <h2>3. Privacy and Safety</h2>
    <ul>
        <li>Respect the privacy and personal information of others.</li>
        <li>Do not share personal or sensitive information about yourself or others without consent.</li>
        <li>Report any suspicious or harmful activities to the game administrators.</li>
    </ul>
    
    <h2>4. Content Sharing</h2>
    <ul>
        <li>Share content (such as images, videos, or text) that is appropriate for all ages.</li>
        <li>Do not share or distribute content that is sexually explicit, violent, or violates copyright laws.</li>
        <li>Respect the intellectual property rights of others.</li>
    </ul>
    
    <h2>5. Cheating and Exploitation</h2>
    <ul>
        <li>Do not use or promote cheats, hacks, or exploits that provide unfair advantages.</li>
        <li>Report any suspected cheating or exploitative activities to the game administrators.</li>
    </ul>
    
    <h2>6. Reporting and Enforcement</h2>
    <ul>
        <li>Report any violations of these guidelines by contacting us at <a href="mailto:support@avenlabs.com">support@avenlabs.com</a>.</li>
        <li>Game administrators reserve the right to take appropriate actions, including warnings, temporary suspensions, or permanent bans, for violations of these guidelines.</li>
        <li>Additionally, game administrators may prevent any content, including usernames, that violates these guidelines from being shown to other players.</li>
    </ul>
    
    <p>
        Remember, these guidelines are subject to change, and it is your responsibility to stay updated with the latest version. Failure to comply with these guidelines may result in disciplinary actions, including account suspension or termination.
    </p>
    
    <p>
        Thank you for being part of our community and helping us create a welcoming and enjoyable gaming environment for everyone!
    </p>

    <small>Last updated: May 15, 2023</small>
  </div>
</template>

<script>
export default {
  name: 'View-ConclaveCommunityGuidelines'
}
</script>
