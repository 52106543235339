<!-- Menu.vue component -->
<template>
    <transition name="fade">
      <div class="menu" v-if="showMenu">
        <button class="menu-item" @click="pushRoute('/')">Conclave</button>
        <button class="menu-item" @click="pushRoute('/support')">Support</button>
        <button class="menu-item" @click="pushRoute('/privacy')">Privacy policy</button>
        <!-- <button class="menu-item" @click="pushRoute('/about')">About us</button> -->
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'Component-Menu',
    props: {
      showMenu: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      pushRoute(path) {
        this.$router.push(path);
        this.$emit('close-menu');
      },
    },
  };
  </script>
  
<style>
    .menu {
      position: absolute;
      right: 0;
      width: 200px;
      height: auto;
      background-color: rgba(0, 0, 0, 0.9);
      color: white;
      padding: 8px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
    }
    
    .menu-item {
    width: 100%;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
    background-color: transparent;
    cursor: pointer;
    /* Add a fixed height for the menu items */
    height: 50px;
  }

  .menu-item:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: rgb(var(--accent-color));
  }

  /* Desktop View */
  .desktop-view .menu {
      top: 60px;
  }

  /* Mobile View */
  .mobile-view .menu {
      top: 40px;
  }
</style>