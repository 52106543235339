<template>
  <div class="page">
    
    <img class="image" src="@/assets/conclave/screen_deckbuilder.png" />

    <div class="text">
      <P>Construct powerful decks</P>
    </div>    
  </div>
</template>

<script>

export default {

};
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  height: 90%;
}
.text{
  z-index: 1;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.desktop-view .text{
  font-size: 28px;
  margin-right: -15px;
  max-width: 200px;
  margin-top: 270px;
}

.mobile-view .text{
  font-size: 20px;
  margin-top: 240px;
  margin-left: -7px;
}

.image {
  height: 100%;
  rotate: 1.5deg;
  user-select: none;
}
</style>