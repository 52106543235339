<template>
  <div class="page">
    <div class="text">
      <P>Develop your strategy</P>
      <P>Counter your opponent</P>
      <P>Emerge victorious</P>
    </div>
    <img class="image" src="@/assets/conclave/screen_game.png" />
  </div>
</template>

<script>

export default {

};
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  height: 90%;
}
.text{
  z-index: 1;
  margin-top: 5%;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.desktop-view .text{
  font-size: 28px;
  margin-right: -12px;
}

.mobile-view .text{
  font-size: 20px;
  margin-right: -22px;
}

.image {
  rotate: 3deg;
  height: 100%;
  user-select: none;
}
</style>