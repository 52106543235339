<template>
  <div v-bind:class="{ 'desktop-view': !isMobile, 'mobile-view': isMobile }" >
    <div class="top-bar">
      <div class="top-left">
        <button class="home-button" @click="handleHomeButtonClick">
          <img class="site-icon" src="./assets/icon_dark.png" />
          Avenlabs
        </button>
      </div>
      <div class="top-right">
        <!-- <button class="sign-in-button">Sign In</button> -->
        <button class="menu-button" @click="handleMenuButtonClick">Menu</button>
        <Menu :show-menu="showMenu" :is-mobile="isMobile" @close-menu="showMenu = false" />
      </div>
    </div>
    
    <router-view :is-mobile="isMobile"/>
    
  </div>
</template>
<script>
import Menu from './components/Menu.vue';

export default {
  name: 'App',
  components: {
    Menu,
  },
  data() {
    return {
      showMenu: false,
      menuButtonClicked: false,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = window.innerWidth <= 768
  },
  mounted() {
    document.addEventListener('click', (event) => {
      // Check if the click was outside of the menu element and the menu button was not clicked
      if (!event.target.closest('.menu') && !this.menuButtonClicked) {
        this.showMenu = false;
      }
      // Reset the menu button clicked flag
      this.menuButtonClicked = false;
    });
  },
  methods: {
    handleHomeButtonClick() {
      this.$router.push('/')
    },
    handleMenuButtonClick() {
      this.showMenu = !this.showMenu;
      this.menuButtonClicked = true;
    },
  },
};
</script>

<style>
  /* https://coolors.co/062c34-15cb75-a61e5e-fff0e5 */
  /* https://coolors.co/14cc75-062C34-cc146b-fff0e5 */
  :root {
    /* #14cc75 */
    --primary-color: 20, 204, 117;
    /* #062C34 */
    --secondary-color: 	6, 44, 52;
    /* #cc146b */
    --accent-color: 	204, 20, 107;
    /* a61057 */
    --accent-color-dark: 166, 16, 87;
    /* fff0e5 */
    --highlight-color: 255, 240, 229;
  }

  @font-face {
    font-family: 'Conclave';
    src: url('assets/conclave/Reforma1918-Gris.otf') format('opentype');
  }

  body{
    padding: 0;
    margin: 0;

    
  }

  .content{
    max-width: 1200px;
    margin: 0 auto; 
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .desktop-view .content {
    font-size: 20px;
  }

  .mobile-view .content {
    font-size: 16px;
  }
</style>

<style scoped>
  .top-bar {
    display: flex;
    justify-content: space-between;
    padding: 0px 14px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: rgba(var(--secondary-color), 0.8);
    backdrop-filter: blur(5px);
    box-shadow: 0px 3px 3px 0px rgba(var(--secondary-color), 0.2);
  }

  .top-left {
    display: flex;
    align-items: center;
  }

  .home-button {
    display: flex;
    align-items: center;
    font-weight: 600;
    border: none;
    background-color: transparent;
  }

  .top-right {
    display: flex;
  }

  .sign-in-button,
  .menu-button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
  }

  .sign-in-button:hover,
  .menu-button:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: rgb(var(--accent-color-dark));
  }

  .sign-in-button {
    margin-right: 16px;
  }

  .home-button,
  .sign-in-button,
  .menu-button {
    cursor: pointer;
    color: rgb(var(--highlight-color));
  }

  

  /* Desktop View */
  .desktop-view .top-bar {
    height: 60px;
  }

  .desktop-view .site-icon {
    width: 54px;
    height: 54px;
    margin-right: 16px;
  }

  .desktop-view .home-button {
    font-size: 24px;
  }

  

  /* Mobile View */
  .mobile-view .top-bar {
    height: 40px;
  }

  .mobile-view .site-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .mobile-view .home-button {
    font-size: 18px;
  }

</style>