<template>
  <div class="content">
    <h1>Support</h1>
    <p>If you have any question or comments, please contact us!</p>
    <p>E-mail: <a href="mailto:support@avenlabs.com">support@avenlabs.com</a></p>
  </div>
</template>

<script>
export default {
  name: 'View-Support'
}
</script>