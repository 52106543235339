<template>
  <div>
    Home
  </div>
</template>
  
<script>
  export default {
    name: "View-Home",
    props: {
      isMobile: {
        type: Boolean,
        required: true,
      },
    },
}
</script>


<style scoped>
  
</style>