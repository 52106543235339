<template>
  <div>
    <div class="bg">
      <img class="bg-img" src="../assets/conclave/background.png" />
    </div>

    <div class="content">
      <h1 class="title"> {{ text }}</h1>

    </div>
  </div>
</template>

<script>

export default {
  name: "View-ConclaveUnsubscribeEmail",
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      text: "Unsubscribe successful",
    };
  },
  mounted() {
    this.unsubscribe();
  },
  methods: {
    unsubscribe() {
      let email = this.$route.query.email;
      if(!email){
        return
      }
      
      const body = JSON.stringify({data: {email: email}});

      fetch("https://europe-west1-conclavedev-be5d1.cloudfunctions.net/emailUnsubscribe", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      })
      .then(response => {
        this.isClaiming = false;
        if (!response.ok) {
          return response.text().then(text => { throw new Error(text) })
        }
        return response.json();
      })
      .then(data => {
      })
      .catch(error => {
        this.text = "Unsubscribe failed, try again later"
      });
    }
  },
};
</script>
  
  <style scoped>
  .bg {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    background-color: black;
  }
  .bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(20%) blur(10px);
  }
  
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(var(--highlight-color));
    font-family: 'Conclave';
  }
  
  .title {
    font-weight: normal;
    font-family: 'Conclave';
    font-size: 28px;
    margin-top: 70px;
  }


</style>
