<template>
    <div class="description">
        <p>
            Prove your strategic prowess against your friends or embark on a single player campaign to conquer the world!
        </p>
        <p>
            Defeat your opponents in the epic strategy card game <b>Conclave</b> by building a formidable army and leading it into battle.
            However, brute force isn't the only path to victory - you can also support your Creatures with powerful spells such as <i>Siren's&nbsp;Song</i>, which entrances your opponents' units, or artifacts like <i>Narrow&nbsp;Bridge</i>, preventing your opponent from swarming you with many creatures at once.
            Every decision is crucial on your journey to victory. You will have to forgo some cards in order to gather the resources necessary to play your more powerful cards.
            Will you choose to overwhelm your opponents with raw strength, or outmaneuver them with clever use of spells and artifacts? The choice is yours in Conclave.
        </p>
        <p>
            Are you ready to test your strategic prowess? Play now to find out!
        </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Component-ConclaveDescription'
  }
  </script>

<style scoped>
.desktop-view .description {
    text-align: center;
}

.mobile-view .description {
    padding-left: 15px;
    padding-right: 15px;
  }
</style>