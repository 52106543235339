<template>
  <div class="content">
    <h1>Privacy Policy for Conclave and Avenlabs.com</h1>
    <p>Last updated: March 10, 2023</p>
    <p>Thank you for using Conclave (the "Game") and visiting Avenlabs.com (the "Website"). This Privacy Policy describes how your personal information is collected, used, and shared when you use the Game and the Website. By using the Game and the Website, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
    <h2>Information We Collect</h2>
    <p>When you use Conclave, we may collect the following types of information:</p>
    <ul>
      <li>Personal Information</li>
      <p>We may collect personal information that you provide to us, such as your name, email address, and other contact information. We collect this information when you create an account, submit a support request, or communicate with us in any other way.</p>
      <li>Usage Information</li>
      <p>We may collect information about how you use Conclave, including your device type, operating system, IP address, and mobile network information. We may also collect information about the actions you take within Conclave, matches you play, items you purchase, and other gameplay data.</p>
    </ul>
    <p>We use Google Firebase for user authentication and data storage. Google Firebase collects information on our behalf to provide these services, such as your email address, user ID, and device information. For more information on how Google Firebase collects and uses your information, please review their <a href = "https://firebase.google.com/support/privacy">privacy policy</a>.</p>
    <h2>How We Use Your Information</h2>
    <p>We use your information to provide and improve the Game and the Website, personalize your experience, communicate with you, and comply with legal obligations. We may also use your information to send you promotional messages, which you can opt-out of at any time.</p>
    <h2>How We Share Your Information</h2>
    <p>We only share your information with third-party service providers who help us operate and improve the Game and the Website, such as Google Firebase. We may also share your information if required by law or if we believe that it is necessary to protect our rights or the rights of others. However, we want to reassure you that we do not sell your data or share it with any third parties for any other reason than the functionality of the game.</p>
    <h2>Your Choices</h2>
    <p>You can choose not to provide us with certain information, but this may limit your ability to use certain features of the Game and the Website. You can also opt-out of promotional messages by following the instructions in the messages.</p>
    <h2>Security</h2>
    <p>We take reasonable measures to protect your information from unauthorized access and disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
    <h2>Children's Privacy</h2>
    <p>The Game and the Website are not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13.</p>
    <h2>Deleting User Data</h2>
    <p>You can delete your account in the Game, which will result in the deletion of your personal information stored in Google Firebase. However, please note that we may retain certain information for a limited period of time for legal, regulatory, and legitimate business purposes, such as to prevent fraud and to resolve disputes. Additionally, we may continue to use aggregated and de-identified information that does not personally identify you. If you have any questions or concerns about deleting your data, please contact us.</p>
    <h2>Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on the Website. Your continued use of the Game and the Website after the effective date of the updated Privacy Policy will constitute your acceptance of the changes.</p>
    <h2>Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@avenlabs.com">support@avenlabs.com</a>.</p>
    <p>By using Conclave and visiting Avenlabs.com, you acknowledge that you have read this Privacy Policy and understand your rights in relation to your personal information.</p>
  </div>
</template>

<script>
export default {
  name: 'View-PrivacyPolicy'
}
</script>