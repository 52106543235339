<template>
  <div class="page">
    <img class="image" src="@/assets/conclave/screen_combat.png" />
    <div class="text">
      <P>Command intense battles</P>
    </div>
  </div>
</template>

<script>

export default {

};
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  height: 90%;
}
.text{
  z-index: 1;
  margin-top: 2%;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.desktop-view .text{
  font-size: 28px;
  margin-top: -22px;
  margin-bottom: -18px;
}

.mobile-view .text{
  font-size: 20px;
  margin-top: -22px;
  margin-bottom: -18px;
}

.image {
  object-fit: contain;
  height: 90%;
  user-select: none;
}
</style>